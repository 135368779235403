<template>
    <div class="home">
        <v-card>
            <v-container fluid>
                <v-row><v-col cols="12"></v-col></v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card outlined>
                                <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                                    <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                                    GROUP FILTER
                                    </div>
                                </v-card-title>
                                <v-row class="pa-4">
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <v-alert text class="mt-2 mb-0" color="info" icon="mdi-cloud-alert" border="left">
                                            <p class="mb-0"> Group book mapping system </p>
                                        </v-alert>
                                        <!-- <Description :name="'Position Snapshot'"/> -->
                                    </v-col>
                                </v-row>

                                <v-row class="pa-4">
                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" v-if="brokerMap.length > 1">
                                        <v-autocomplete label="Filter Broker" v-model="selectedBrokerId" :items="brokerMap"
                                            item-text="broker" item-value="broker_id" auto-select-first chips clearable deletable-chips
                                            small-chips></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <v-btn dense color="primary" v-on:click="triggerModal()"
                                            :disabled="tableData.selectedData.length === 0">BATCH UPDATE</v-btn>
                                    </v-col>
                                </v-row>

                                <v-row class="pa-4" dense>
                                    <v-col cols="12">
                                        <h4>{{ selectedBroker }} Group Book Data</h4>
                                    </v-col>
                                </v-row>

                                <v-dialog v-model="prefix.visible" max-width="1200">
                                    <v-card>
                                        <v-card-title class="headline">
                                            {{ prefix.title }}
                                        </v-card-title>

                                        <v-card-actions>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                                                    <v-combobox label="Book Name (Select or Type)" v-model="selectedBook"
                                                        :items="listBook" auto-select-first chips clearable deletable-chips small-chips>
                                                    </v-combobox>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                                                    <v-autocomplete label="Active Status" v-model="selectedActive" :items="activeList"
                                                        item-text="text" item-value="value" auto-select-first chips clearable
                                                        deletable-chips small-chips></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                                                    <v-text-field label="Server" v-model="selectedServer" hide-details="auto">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                                                    <v-text-field label="Book To Bridge" v-model="bookToBridge" hide-details="auto">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                                                    <v-text-field label="Book To Pk" v-model="bookToPk" hide-details="auto">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                    <v-alert text color="info" icon="mdi-cloud-alert" border="left">
                                                        <p>The following group will be update: </p>
                                                        <p class="mb-0">
                                                            <span v-for="item in tableData.selectedData"
                                                                :key="item.id">{{ item.group }},</span>
                                                        </p>
                                                    </v-alert>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                    <v-btn dense v-on:click="bookGroupHandler().update()" color="primary"
                                                        :loading="btnLoading">UPDATE</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-actions>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="green darken-1" text @click="prefix.visible = false">Cancel</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-row>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <!-- <v-text-field class="mt-n2 mb-2" v-model="tableData.search" append-icon="mdi-search" label="Search" single-line hide-details>
                                        </v-text-field> -->

                                        <v-data-table dense :search="tableData.search" :headers="headers" :items="filteredDataSource"
                                            class="elevation-1" :single-select="false" show-select v-model="tableData.selectedData"
                                            :items-per-page="tableData.itemsPerPage">

                                            <template v-slot:header="{ props: { headers } }">
                                                <thead>
                                                    <tr><th :colspan="headers.length"></th></tr>
                                                </thead>
                                            </template>

                                            <template v-slot:header.group="{ header }">
                                                {{ header.text }}
                                                <v-menu offset-y :close-on-content-click="false">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon v-bind="attrs" v-on="on">
                                                            <v-icon small :color="groupName ? 'primary' : ''">
                                                                mdi-filter
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <div style="background-color: #1e1e1e; width: 280px; ">
                                                        <v-text-field v-model="groupName" class="pa-4" type="text"
                                                            label="Enter the search term" style="width: 100%;"></v-text-field>
                                                        <v-btn @click="groupName = ''" small text color="primary" class="ml-2 mb-2">
                                                            Clean
                                                        </v-btn>
                                                    </div>
                                                </v-menu>
                                            </template>

                                            <template v-slot:header.book="{ header }">
                                                {{ header.text }}
                                                <v-menu offset-y :close-on-content-click="false">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon v-bind="attrs" v-on="on">
                                                            <v-icon small :color="book ? 'primary' : ''">
                                                                mdi-filter
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <div style="background-color: #1e1e1e; width: 280px">
                                                        <v-text-field v-model="book" class="pa-4" type="text"
                                                            label="Enter the search term"></v-text-field>
                                                        <v-btn @click="book = ''" small text color="primary" class="ml-2 mb-2">
                                                            Clean
                                                        </v-btn>
                                                    </div>
                                                </v-menu>
                                            </template>
                                            <template v-slot:header.active="{ header }">
                                                {{ header.text }}
                                                <v-menu offset-y :close-on-content-click="false">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon v-bind="attrs" v-on="on">
                                                            <v-icon small :color="active ? 'primary' : ''">
                                                                mdi-filter
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <div style="background-color: #1e1e1e; width: 150px">
                                                        <v-container class="pb-0 pt-1" fluid>
                                                            <v-radio-group v-model="active" class="mt-0 mb-0 pb-0">
                                                                <v-radio label="Active" value="1"></v-radio>
                                                                <v-radio label="Inactive" value="0"></v-radio>
                                                            </v-radio-group>
                                                        </v-container>
                                                        <v-btn @click="active = ''" small text color="primary" class="ml-2 mb-2">
                                                            Clean
                                                        </v-btn>
                                                    </div>
                                                </v-menu>
                                            </template>

                                            <template v-slot:item.active="{ item }">
                                                <v-chip small class="ma-2" color="primary" v-if="item.active === 1">Active</v-chip>
                                                <v-chip small class="ma-2" color="red" v-if="item.active === 0">Inactive</v-chip>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>

                            </v-card>
                        </v-col>
                    </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import * as apiBook from "@components/api/pk/book";
import * as apiBookGroup from "@components/api/pk/book-group";
import { helper } from "@components/mixins/helper";
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";

import moment from "moment";
import { mapState } from "vuex";
moment.locale("en-au");

export default {
    components: { },
    mixins: [helper, snackbar, permission],
    data () {
        return {
            groupName: "",
            book: "",
            active: "",
            selectedKey: -1,
            selectedBroker: "",
            selectedBrokerId: -1,
            btnLoading: false,
            headers: [
                { text: "Group", value: "group", align: "start", sortable: false },
                { text: "Book", value: "book" },
                { text: "Active", value: "active" },
                { text: "OZ Book", value: "oz_book" },
                { text: "Book Bridge", value: "book_to_bridge" },
                { text: "Book PK", value: "book_to_pk" },
                { text: "Server", value: "server" },
                { text: "Created At", value: "created_at" },
                { text: "Updated At", value: "updated_at" }
            ],
            tableData: {
                selectedData: [],
                dataSource: [],
                itemsPerPage: 20,
                search: ""
            },
            prefix: {
                visible: false,
                loading: false,
                title: ""
            },
            bookToPk: "",
            bookToBridge: "",
            selectedBook: "",
            listBook: [],
            selectedActive: 1,
            selectedServer: "LIVE-1",
            activeList: [
                { text: "Active (Will show on PK)", value: 1 },
                { text: "Inactive (Will not show on PK)", value: 0 }
            ],

            filters: { group: [], book: [], active: [] },
            activeFilters: {},
            editedIndex: -1
        };
    },
    watch: {
        selectedBrokerId (nv, ov) {
            this.bookGroupHandler().load();
            this.loadBook();
            this.selectedBroker = this.getBrokerNameById(nv);
        },
        "tableData.selectedData" (nv, ov) {}
    },
    computed: {
        ...mapState(["brokerMap"]),
        filteredDataSource () {
            const conditions = [];
            if (this.groupName) {
                conditions.push(this.filterGroupName);
            }
            if (this.book) {
                conditions.push(this.filterBook);
            }
            if (this.active) {
                conditions.push(this.filterActive);
            }
            if (conditions.length > 0) {
                return this.tableData.dataSource.filter((item) => {
                    return conditions.every((condition) => {
                        return condition(item);
                    });
                });
            }
            return this.tableData.dataSource;
        }
        // "tableData.dataSource"(nv,ov){
        //     console.log(nv)
        // }
        // headers() {
        //     return [
        //         {
        //             text: "Group", value: "group",
        //             filter: value => {
        //                 return this.activeFilters.name ? this.activeFilters.name.includes(value) : true;
        //             }
        //         },
        //         {
        //             text: "Book", value: "book",
        //             filter: value => {
        //                 return this.activeFilters.name ? this.activeFilters.name.includes(value) : true;
        //             }
        //         },
        //         {
        //             text: "Active", value: "active",
        //             filter: value => {
        //                 return this.activeFilters.name ? this.activeFilters.name.includes(value) : true;
        //             }
        //         },
        //         { text: "Created At", value: "created_at" },
        //         { text: "Updated At", value: "updated_at" },
        //     ]
        // }
    },
    methods: {
        initialFilter () {
            for (const col in this.filters) {
                this.filters[col] = this.tableData.dataSource
                    .map((d) => {
                        return d[col];
                    })
                    .filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    });
            }
            this.activeFilters = Object.assign({}, this.filters);
            // console.log(this.activeFilters);
            // console.log("Mother");
        },
        toggleAll (col) {
            this.activeFilters[col] = this.tableData.dataSource
                .map((d) => {
                    return d[col];
                })
                .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                });
        },
        clearAll (col) {
            this.activeFilters[col] = [];
        },
        close () {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save () {
            if (this.editedIndex > -1) {
                Object.assign(
                    this.tableData.dataSource[this.editedIndex],
                    this.editedItem
                );
            } else {
                this.tableData.dataSource.push(this.editedItem);
            }
            this.close();
        },

        triggerModal () {
            this.prefix.visible = true;
            this.prefix.title = "Batch Modify Group";
        },
        /**
         * 读取broker的组
         * @param {*}
         */
        loadBook () {
            const self = this;
            const params = { broker_id: self.selectedBrokerId, active: 0 };
            apiBook.fetch(params).then((res) => {
                self.listBook = res.data.map((i) => i.book);
                // console.log(self.listBook);
            });
        },
        /**
         * Book Group处理器
         * @return {[type]} [description]
         */
        bookGroupHandler () {
            const self = this;
            return {
                /**
                 * 读取现有book group
                 * @return {[type]} [description]
                 */
                load () {
                    const params = { broker_id: self.selectedBrokerId };
                    self.tableData.dataSource = [];

                    apiBookGroup.fetch(params).then((res) => {
                        self.tableData.dataSource = res.data;
                        self.initialFilter();
                    });
                },
                /**
                 * 更新book group
                 * @return {[type]} [description]
                 */
                update () {
                    const pk = [];

                    for (let i = 0; i < self.tableData.selectedData.length; i++) {
                        let currentData = self.tableData.selectedData[i]
                        pk.push(currentData.id);
                    }

                    const brokerName = self.getBrokerNameById(self.selectedBrokerId);

                    if (
                        self.selectedBook === "" ||
                        self.selectedBook === null ||
                        self.selectedBook === undefined
                    ) {
                        self.snackBarDanger("Book cannot be empty!");
                        return;
                    }

                    if (
                        self.selectedActive === null ||
                        self.selectedActive === undefined
                    ) {
                        self.snackBarDanger("Active cannot be empty!");
                        return;
                    }

                    const params = {
                        pk: pk,
                        broker_name: brokerName,
                        book: self.selectedBook,
                        active: self.selectedActive,
                        broker_id: self.selectedBrokerId,
                        server: self.selectedServer,
                        bookToBridge: self.bookToBridge,
                        bookToPk: self.bookToPk
                    };
                    
                    self.btnLoading = true;
                    apiBookGroup.update(params).then((res) => {
                        setTimeout(() => {
                            self.snackBarSuccess("Update Successfully");
                            self.btnLoading = false;
                            self.prefix.visible = false;
                            self.bookGroupHandler().load();
                        }, 1000);
                    });
                }
            };
        },
        filterGroupName (item) {
            return item.group
                .toLowerCase()
                .includes(this.groupName.toLowerCase());
        },
        filterBook (item) {
            return item.book.toLowerCase().includes(this.book.toLowerCase());
        },
        filterActive (item) {
            return item.active.toString().includes(this.active);
        }
    },
    mounted () {
        const brokerPermission = this.loadDefaultBrokerData();
        this.selectedBrokerId = brokerPermission.selectedBrokerId;
        this.selectedBroker = brokerPermission.selectedBroker;

        this.bookGroupHandler().load();
        this.loadBook();
    }
};
</script>
